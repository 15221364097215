import dayjs from 'dayjs'

export const dealfinderUtils = {
  monthListKeyFormat: 'M/YYYY',

  dateIsDiscounted: (date) => {
    return date.regularPricePerPerson && date.regularPricePerPerson > date.price
  },

  parseTripLengthsByPackagesAndDurations: (packages, durations) => {
    return (durations || [])
      .map(duration => ({
        ...duration,
        name: duration?.value || duration?.nameValue,
        type: duration?.type || 'value',
        dates: (packages || [])
          .filter(p => dealfinderUtils.filterPackagesByDuration(p, duration))
          .map(transformDealFinderPackages),
      }))
  },

  filterPackagesByDuration: (hotelPackage, duration) => {
    if (duration.type === 'custom') {
      return hotelPackage.days >= duration.custom[0] && hotelPackage.days <= duration.custom[1]
    }
    return hotelPackage.days >= duration.value && hotelPackage.days <= duration.maxValue
  },

  findDatesByDuration: (listDuration, checkingDuration) => {
    const keys = ['value', 'name', 'nameValue', 'maxValue']
    const cases = keys
      .map(key => checkingDuration?.value?.[key] === listDuration?.[key] || checkingDuration?.[key] === listDuration?.[key])
      .filter(Boolean)

    return cases.length > 0
  },

  findDaygroupByDuration: ({ min, max }, { value, custom }) => (
    +min === +value?.value && +max === +value?.maxValue) || (+min === +custom?.[0] && +max === +custom?.[1]
  ),

  transformDayGroupToDurationValue: d => ({
    ...d,
    value: d?.min,
    maxValue: d?.max,
    nameValue: d?.name,
  }),

  prefillDaygroups: (daygroups, duration) => {
    if (!daygroups) { return duration }
    const durationExists = (daygroups || []).find(dg => dealfinderUtils.findDaygroupByDuration(dg, duration))
    if (durationExists) {
      duration.value = dealfinderUtils.transformDayGroupToDurationValue(durationExists)
    } else {
      const dayGroup = daygroups?.[0]
      if (dayGroup) {
        duration.value = dealfinderUtils.transformDayGroupToDurationValue(dayGroup)
      }
    }
    return duration
  },

  parseDayLengthFromQuery: (query) => {
    let d = null
    const queryD = query?.d
    if (queryD) {
      d = parseInt(queryD) || 0
    } else if (query?.date && query?.date_end) {
      const d1 = dayjs(query?.date)
      const d2 = dayjs(query?.date_end)
      if (d1.isValid() && d2.isValid()) {
        d = d2.diff(d1, 'days')
      }
    }
    return d
  },

  transformCheapestDate: d => ({
    ...d,
    price: d?.price || 0,
    djs: dayjs(d.date),
    key: dayjs(d.date).format(dealfinderUtils.monthListKeyFormat),
  }),

  reduceCheapestDate: (b, { djs, key, ...d }) => ({
    ...b,
    [key]: (!b?.[key] || parseFloat(d.price) < parseFloat(b?.[key].price)) ? {
      ...d,
      y: djs.format('YYYY'),
      m: djs.format('MMM'),
      ts: djs.unix(),
    } : b[key],
  }),
}